export const blogData = [
    {
        id: 1,
        title: 'Strefa Odklejenia CAŁA PRAWDA!',
        description: 'Cała prawda o firmie i jej ',
        date: 'Aug 7, 2020',
        image: 'https://jspace.pl/imgbro/blog1.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/scraping-billboard-and-imdb-using.html'
    },
    {
        id: 2,
        title: 'Oj Wojtuśiu, znaczenie, zastosowania',
        description: 'Coraz bardziej popularne określenie w firmie yax',
        date: 'Aug 11, 2020',
        image: 'https://jspace.pl/imgbro/blog2.jpg',
        url: 'https://hackzism.blogspot.com/2020/06/check-weather-from-terminal-using-wttrin.html'
    },
    {
        id: 3,
        title: 'Toksyczni współpracownicy, co robic?',
        description: '5 porad jak poradzić sobie w stresujacej pracy',
        date: 'Aug 14, 2020',
        image: 'https://jspace.pl/imgbro/blog3.jpg',
        url: 'https://hackzism.blogspot.com/2020/06/being-anonymous-beginners-guide.html'
    },
    {
        id: 4,
        title: 'CHECK INTERNET SPEED FROM THE COMMAND LINE',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: 'https://1.bp.blogspot.com/-XTVMsZP3HaM/Xxpl394txlI/AAAAAAAABws/zovXWiuNGg4TzBwYYVFfJ1ixDb3JfVokgCNcBGAsYHQ/w400-h195/speedtest.png',
        url: 'https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html'
    },
    {
        id: 5,
        title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/how-can-you-get-michaels-or-trevors.html'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/