import one from '../assets/gif/p1.gif'
import two from '../assets/gif/p2.gif'
import three from '../assets/gif/p3.gif'
import four from '../assets/gif/p4.gif'



export const projectsData = [
    {
        id: 1,
        projectName: 'MeetMap Genarator',
        projectDesc: 'A meet-map finder with generate img with same custom mark and label :#',
        tags: ['#Vue', '#CSS', '#Material Ui'],
        code: 'https://github.com/janszarwaryn/meet-map-generator',
        demo: 'https://meet-map.jspace.pl',
        image: one
    },
    {
        id: 2,
        projectName: 'REST API Vue form',
        projectDesc: 'Create simple Contact Form App for collecting messages and posting them to REST API',
        tags: ['#Vue','#axios', '#bootstrap', '#unit testing', '#jest'],
        code: 'https://github.com/janszarwaryn/REST-API-Vue-form',
        demo: 'http://test.jspace.pl/',
        image: two
    },
    {
        id: 3,
        projectName: 'Eisenhower Matrix',
        projectDesc: 'Full drag and drop matrix to better estimate your time',
        tags: ['#Vue', '#Tailwind'],
        code: 'https://github.com/janszarwaryn/eisenhower-matrix-vue',
        demo: 'http://todo.jspace.pl',
        image: three
    },
    {
        id: 4,
        projectName: 'Internship Task',
        projectDesc: 'Simple task for recrutment proces to our frontend team intership',
        tags: ['#intership task'],
        code: 'https://github.com/janszarwaryn/YaxFrontendTask',
        demo: 'http://test.jspace.pl/',
        image: four
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/