export const experienceData = [
    {
        id: 1,
        company: 'Mercedes-Benz.io | Software Company',
        jobtitle: 'Frontend Engineer',
        startYear: 'Sep 2022 ',
        endYear: ' Present',
    },
    {
        id: 2,
        company: 'Yax | Software House & Venture Builder',
        jobtitle: 'Frontend Engineer',
        startYear: 'Jun 2019 ',
        endYear: ' Sep 2022',
    },
    {
        id: 3,
        company: '1450 | Marketing Agency',
        jobtitle: 'Frontend Engineer',
        startYear: 'Jan 2017 ',
        endYear: ' Jan 2019'
    },
    {
        id: 4,
        company: 'Unit4 | Software Company',
        jobtitle: 'Automatic Tester',
        startYear: 'Mar 2016 ',
        endYear: ' Jan 2017'
    },
]
