/*import resume from '../assets/pdf/resume.pdf'*/
import jano from '../assets/png/jspace.png'

export const headerData = {
    title: "Hello,",
    logoName: "J space",
    name: "I'm Johnny",
    desciption: "from Poland",
    image: jano,
    /*    resumePdf: resume, blokada na pobieranie*/
}
