export const educationData = [
    {
        id: 1,
        institution: 'Wroclaw University of Science and Technology',
        course: 'Cybersecurity | Master Degree',
        startYear: '2020 ',
        endYear: ' 2023'
    },
    {
        id: 1,
        institution: 'Wroclaw University of Science and Technology',
        course: 'IT and Menagment | Engineering Degree',
        startYear: '10.2016 ',
        endYear: ' 01.2020'
    },
    {
        id: 3,
        institution: 'Technical College 7 name. Polskich Zwycięzców Enigmy',
        course: 'IT specialist ',
        startYear: '2012 ',
        endYear: ' 2016'
    },

]
