/* eslint-disable */
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { SiMusicbrainz } from "react-icons/si";
import { CgIfDesign } from "react-icons/cg";
import { GrOptimize } from "react-icons/gr";
import { IoMdOptions } from "react-icons/io";
import { MdSecurity } from "react-icons/md";
import { AiOutlineFundProjectionScreen, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { FaTableTennis } from "react-icons/fa";
import { FiServer } from "react-icons/fi";



export const servicesData = [
    {
        id: 1,
        title: 'Music Producer',
        icon: <SiMusicbrainz/>
    },
    {
        id: 2,
        title: 'Web Design',
        icon: <CgIfDesign />
    },
    {
        id: 3,
        title: 'Website Creator',
        icon: <BsCodeSlash />
    },
    {
        id: 4,
        title: 'SEO',
        icon: <GrOptimize />
    },
    {
        id: 5,
        title: 'Video Editing',
        icon: <AiOutlineVideoCameraAdd />
    },
    {
        id: 6,
        title: 'Website Optimization',
        icon: <IoMdOptions />
    },
    {
        id: 7,
        title: 'Security Upgrades',
        icon: <MdSecurity />
    },
    {
        id: 8,
        title: 'Project Management Support',
        icon: <AiOutlineFundProjectionScreen />
    },
    {
        id: 9,
        title: 'Table Tennis Trainings :P',
        icon: <FaTableTennis />
    },
    {
        id: 10,
        title: 'MC Server Support',
        icon: <FiServer />
    },
/*    {
        id: 12,
        title: 'Audio Transcription',
        icon: <FaTableTennis />
    },
    {
        id: 13,
        title: 'Blog Posting',
        icon: <FaRegNewspaper />
    },*!/*/

]

// Uncomment your required service.
