export const aboutData = {
    title: "About me",
    description1: "Self-taught web developer based in Wrocław specializing in front-end technologies. A hardworking and avid learner who started developing websites in 2019, working for companies with important eCommerce sites, like Unit4 ; as well as fast growing startups, like Yax | Ratingcaptain where I had the chance to work in projects for clients like Smake, Inspirion, Drop.",
    description2: "My core skills are HTML, CSS, and JavaScript all the way to modern frameworks like Vue and React, working under Agile/Scrum development environments.",
    description3: "I'm a team player, used to work under pressure, putting the company goals first. I'm proactive, flexible and perfectionist, always looking for new ways to improve the software development processes.",
    description4: "Frontend Team Leader: half of year+ on Front-End Team Leader experience in SaaS, data analytics and managing medium-scale web application projects. Monitoring and control of ongoing projects, estimation of work and tasks for project members. Ability to multitask and to manage multiple priorities across teams that are geographically dispersed.\n",
    image: 2
}





