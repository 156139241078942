import React, {useContext, useState} from 'react'
import {NavHashLink as NavLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
import {IoMenuSharp, IoHomeSharp} from "react-icons/io5";
import {HiDocumentText} from "react-icons/hi";
import {BsFillGearFill} from "react-icons/bs";
import {MdPhone} from "react-icons/md";
import {FaUser, FaFolderOpen, FaLinkedin, FaGithub, FaInstagram} from "react-icons/fa";
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import './Navbar.css'
import {headerData} from '../../data/headerData'
import {ThemeContext} from '../../contexts/ThemeContext'
import {socialsData} from "../../data/socialsData";

function Navbar() {

    const {theme, setHandleDrawer} = useContext(ThemeContext)

    const [open, setOpen] = useState(false);

    var audio = new Audio("https://jspace.pl/hi_johnny.ogg")
    const start = () => {
        audio.play()
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        setHandleDrawer()

    };

    const handleDrawerClose = () => {
        setOpen(false);
        setHandleDrawer()
    };

    const useStyles = makeStyles((t) => ({
        navMenu: {
            fontSize: '2.5rem',
            color: theme.tertiary,
            cursor: 'pointer',
            transform: 'translateY(-10px)',
            transition: 'color 0.3s',
            "&:hover": {
                color: theme.primary,
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '2.5rem',
            },
            [t.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        MuiDrawer: {
            padding: '0em 1.8em',
            width: '14em',
            fontFamily: ' var(--primaryFont)',
            fontStyle: ' normal',
            fontWeight: ' normal',
            fontSize: ' 24px',
            background: theme.secondary,
            overflow: 'hidden',
            // borderTopRightRadius: '40px',
            // borderBottomRightRadius: '40px',
            [t.breakpoints.down('sm')]: {
                width: '12em',
            },
        },
        closebtnIcon: {
            fontSize: '2rem',
            fontWeight: 'bold',
            cursor: 'pointer',
            color: theme.primary,
            position: 'absolute',
            right: 40,
            top: 40,
            transition: 'color 0.2s',
            "&:hover": {
                color: theme.tertiary,
            },
            [t.breakpoints.down('sm')]: {
                right: 20,
                top: 20,
            },
        },
        drawerItem: {
            margin: '1rem auto',
            borderRadius: '78.8418px',
            background: theme.secondary,
            color: theme.primary,
            width: '85%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            // padding: '0 30px',
            boxSizing: 'border-box',
            // border: '2px solid',
            borderColor: theme.primary,
            transition: 'background-color 0.2s, color 0.2s',
            "&:hover": {
                background: theme.primary,
                color: theme.secondary,
                width: '100%',
            },
            [t.breakpoints.down('sm')]: {
                width: '100%',
                // padding: '0 25px',
                height: '55px',
            },
        },
        drawerLinks: {
            fontFamily: "var(--primaryFont)",
            width: '50%',
            fontSize: '1.3rem',
            fontWeight: 600,
            [t.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
        },
        drawerIcon: {
            fontSize: '1.6rem',
            [t.breakpoints.down('sm')]: {
                fontSize: '1.385rem',
            },
        }
    }));

    const classes = useStyles();


    const shortname = (name) => {
        if (name.length > 12) {
            return name.split(" ")[0]
        } else {
            return name
        }
    }


    return (
        <div className="navbar">
            <div className="navbar--container">
                <h1 style={{color: theme.secondary}} onClick={start}>{shortname(headerData.logoName)}</h1>

                <IoMenuSharp className={classes.navMenu} onClick={handleDrawerOpen}/>
            </div>
            <Drawer
                variant="temporary"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleDrawerClose()
                    } else if (reason !== 'escapeKeyDown') {
                        handleDrawerClose()
                    }
                }}
                anchor="left"
                open={open}
                classes={{paper: classes.MuiDrawer}}
                className="drawer"
                disableScrollLock={true}
            >
                <div className="div-closebtn">
                    <CloseIcon onClick={handleDrawerClose} className={classes.closebtnIcon}/>
                </div>
                <br/>


                <div onClick={handleDrawerClose}>
                    <div className="navLink--container">
                        <Fade left>
                            <NavLink to="/" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <IoHomeSharp className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Home</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <FaUser className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>About</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#skills" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <HiDocumentText className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Skills</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#experience" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <HiDocumentText className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Experience</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#projects" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <BsFillGearFill className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Portfolio</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#achievement" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <FaFolderOpen className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Achievement</span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink to="/#services" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <FaFolderOpen className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Services</span>
                                </div>
                            </NavLink>
                        </Fade>


                        <Fade left>
                            <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
                                <div className={classes.drawerItem}>
                                    <MdPhone className={classes.drawerIcon}/>
                                    <span className={classes.drawerLinks}>Contact</span>
                                </div>
                            </NavLink>
                        </Fade>
                        <div className="landing--mobile--social">
                            {socialsData.linkedIn && (
                                <a href={socialsData.linkedIn} target="_blank" rel="noreferrer">
                                    <FaLinkedin className="landing--social" style={{color: theme.secondary}}/>
                                </a>
                            )}
                            {socialsData.github && (
                                <a href={socialsData.github} target="_blank" rel="noreferrer">
                                    <FaGithub className="landing--social" style={{color: theme.secondary}}/>
                                </a>
                            )}
                            {socialsData.instagram && (
                                <a href={socialsData.instagram} target="_blank" rel="noreferrer">
                                    <FaInstagram className="landing--social" style={{color: theme.secondary}}/>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Navbar
