import React from 'react'
import {Helmet} from 'react-helmet'
import Particle from "../../components/Particle";

import {
    Navbar,
    Footer,
    Landing,
    About,
    Skills,
    Testimonials,
    Blog,
    Education,
    Experience,
    Contacts,
    Projects,
    Services,
    Achievement
} from '../../components'
import {headerData} from '../../data/headerData'


function Main() {
    return (

        <div>
            <Particle></Particle>
            <Helmet>
                <title>JSpace - Portfolio site Johnny | Janek Szarwaryn</title>
            </Helmet>
            <Navbar/>
            <Landing/>
            <About/>
            <Skills/>
            <Experience/>
            <Projects/>
            <Education/>
            <Achievement/>
            <Services/>
            {/*<Testimonials />*/}
            {/*<Blog />*/}
            <Contacts/>
            <Footer/>
        </div>
    )
}

export default Main
