import googlepm from '../assets/png/google_pm.webp'
import geekle from '../assets/png/cert-vue.webp'

export const achievementData = {
    bio : "Continuous development and self-improvement is needed to achieve success, here is a list of my currently performed certificates and trainings. :)",
    achievements : [
        {
            id : 1,
            title : 'Project Management',
            details : 'Project managers ensure projects within an organization are managed and completed with maximum value.',
            date : 'Aug 2, 2021',
            field : 'Project Manager | Certificate',
            image : googlepm,
        },
        {
            id : 2,
            title : 'Geekle Vue certificate | Vue.js Global Summit-22',
            details : 'Event about Composition & Setup API Testing, Unit test Nuxt.js and Vue.js - whats new, changes, improvements Micro-frontends Tailwind CSS Vue 3 with TypeScript, Vite',
            date : 'May 28, 2022',
            field : 'VueJs | Certificate',
            image : geekle,
        },
        // {
        //     id : 3,
        //     title : 'Professional Data Engineer Certification',
        //     details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
        //     date : 'Aug 20, 2019',
        //     field : 'Automation',
        //     image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        // }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/